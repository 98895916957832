@charset "utf-8";

@import '../mixin';
@import '../variable';

#header::-webkit-scrollbar {
    width: 0.5em;
}

.space-top {
    @media screen and (max-width: $md) {
        padding-top: 80px;
    }
    @media screen and (max-width: $sm) {
        padding-top: 60px;
    }
}

.fixed-contact {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 100px;
    height: 100px;
    background-color: $color-brand;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    transition: $animate;
    z-index: 3;
    @media screen and (max-width: $md) {
        display: none;
    }
    i {
        font-size: 20px;
        margin-bottom: 7px;
    }
    &:hover {
        color: #FFF;
        bottom: 23px;
    }
}

/*HEADER*/
#header {
    width: 300px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    position: fixed;
    transition: $animate;
    overflow: auto;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $xxl) {
        width: 350px;
    }
    @media screen and (max-width: $lg) {
        width: 250px;
    }

    @media screen and (max-width: $md) {
        width: 100%;
        top: 0;
        height: 80px;
        overflow: hidden;
        background-color: #FFF;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: $sm) {
        height: 60px;
    }

    .logo {
        padding: 30px 10px 20px;
        @media screen and (min-width: $xxl) {
            padding: 50px 20px 40px;
        }
        @media screen and (max-width: $lg) {
            padding: 20px 10px 10px;
        }
        @media screen and (max-width: $md) {
            width: auto;
            padding: 0;
            height: auto;
            position: relative;
            border-right: 0;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 246px;
            }
            @media screen and (min-width: $xxl) {
                img {
                    width: 275px;
                }
            }
            @media screen and (max-width: $lg) {
                img {
                    width: 200px;
                }
            }
            @media screen and (max-width: $md) {
                margin: 0;
                width: auto;
                img {
                    width: auto;
                    height: 50px;
                }
            }
            @media screen and (max-width: $sm) {
                img {
                    height: 40px;
                }
            }
        }
    }

    .telp {
        margin: 24px 0;
        text-align: center;
        @media screen and (min-width: $xxl) {
            margin: 32px 0;
        }
        @media screen and (max-width: $md) {
            display: none;
        }
        p {
            color: $color-secondary;
            font-size: 14px;
            font-weight: bold;
            @media screen and (min-width: $xxl) {
                font-size: 18px;
            }
        }
        a {
            font-size: 38px;
            color: $color-brand;
            font-weight: bold;
            line-height: 1.2;
            @media screen and (min-width: $xxl) {
                font-size: 45px;
            }
            @media screen and (max-width: $lg) {
                font-size: 32px;
            }
        }
        span {
            font-size: 16px;
            font-weight: normal;
            @media screen and (min-width: $xxl) {
                font-size: 20px;
            }
        }
    }

    nav {
        position: relative;
        padding: 0 10px;
        @media screen and (min-width: $xxl) {
            padding: 0 20px;
        }
        @media screen and (max-width: $md) {
            display: none;
        }
        ul {
            border: 1px solid $color-brand;
            border-left: 0;
            border-right: 0;
            padding: 20px 10px;
            @media screen and (min-width: $xxl) {
                border: 2px solid $color-brand;
                border-left: 0;
                border-right: 0;
                padding: 30px 20px;
            }
            @media screen and (max-width: $lg) {
                padding: 15px 10px;
            }
            li {
                margin-bottom: 20px;
                @media screen and (min-width: $xxl) {
                    margin-bottom: 30px;
                }
                @media screen and (max-width: $lg) {
                    margin-bottom: 15px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    color: $color-brand;
                    span {
                        color: $color-brand;
                        font-size: 12px;
                        @media screen and (min-width: $xxl) {
                            font-size: 15px;
                        }
                    }
                    p {
                        font-size: 23px;
                        font-weight: bold;
                        line-height: 1;
                        @media screen and (min-width: $xxl) {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }

    .headerSpBtn {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
        background-color: $color-button;
        border: none;
        text-align: center;
        outline: none;
        border-radius: 0;
        transition: all .2s ease-in-out;
        display: none;

        @media screen and (max-width: $md) {
            display: block;
        }

        @media screen and (max-width: $sm) {
            width: 60px;
            height: 60px;
        }
        
        > div {
            position: absolute;
            align-items: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            background-color: $color-white;
            width: 40px;
            transition: all .2s ease-in-out;

            @media screen and (max-width: $sm) {
                width: 30px;
            }

            &::after,
            &::before {
                position: absolute;
                display: inline-block;
                content: '';
                height: 2px;
                width: 100%;
                background-color: $color-white;
                transform-origin: center;
                transition: all .2s ease-in-out;
            }

            &::after {
                bottom: -12px;
                left: 0;
            }

            &::before {
                top: -12px;
                left: 0;
            }
        }

        &._active {

            > div {
                background-color: transparent;
    
                &::after {
                    transform: rotate(45deg);
                    bottom: 0;
                }
    
                &::before {
                    transform: rotate(-45deg);
                    top: 0;
                }
            }
        }

    }
}

/* NAV SP */
.navSp {
    position: fixed;
    top: 80px;
    right: -100%;
    width: 100%;
    height: calc(100% - 80px);
    background-color: #FFF;
    z-index: 19;   
    overflow-y: auto;
    opacity: 1;
    visibility: hidden;
    transition: $animate;
    padding: 5%;
    border-top: 1px solid $color-brand;

    @media screen and (max-width: $sm) {
        height: calc(100% - 60px);
        top: 60px;
    }
    
    &._active {
        right: 0;
        visibility: visible;
    }

    .inner {
        nav {
            ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li {
                    width: 100%;
                    padding: 2.5% 0;
                    border-bottom: 1px solid $color-brand;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: flex;
                        flex-direction: column;
                        color: $color-brand;
                        span {
                            color: $color-brand;
                            font-size: 10px;
                        }
                        p {
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 1;
                            margin-top: 2px;
                        }
                    }
                }
            }
        }
    }
}