@charset "utf-8";

@import '../mixin';
@import '../variable';

/* SASS UNTUK MAINVISUAL */

#mainvisual {
    margin-top: 0;
    position: relative;

    &.topPage {
        .gallery-mv {
            img {
                width: 100%;
            }
        }
    }

    &.subPage {
        height: 283px;
        padding: 60px 0 10px;
        @media screen and (min-width: $xxl) {
            height: 350px;
            padding: 80px 0 20px;
        }
        @media screen and (max-width: $md) {
            background-color: #F7F7F7;
            height: auto;
            padding: 30px 0 15px;
        }
        @media screen and (max-width: $sm) {
            padding: 20px 0 10px;
        }
        .breadcrumb {
            align-items: center;
            margin-bottom: 30px;
            @media screen and (max-width: $sm) {
                margin-bottom: 20px;
            }
            li {
                color: $color-brand;
                font-size: 12px;
                @media screen and (min-width: $xxl) {
                    font-size: 15px;
                }
                a {
                    color: rgba(26,24,24,0.23);
                    transition: $animate;
                    &:hover {
                        color: rgba(26,24,24,1);
                    }
                }
            }
            span {
                margin: 0 15px;
                width: 2px;
                height: 2px;
                background-color: #CACACA;
                @media screen and (min-width: $xxl) {
                    width: 4px;
                    height: 4px;
                }
            }
        }
        h1 {
            font-size: 40px;
            font-weight: bold;
            color: $color-brand;
            margin-bottom: 1rem;
            @media screen and (min-width: $xxl) {
                font-size: 45px;
            }
            @media screen and (max-width: $sm) {
                font-size: 30px;
            }
        }
        p {
            @media screen and (min-width: $xxl) {
                font-size: 18px;
            }
            @media screen and (max-width: $sm) {
                font-size: 14px;
            }
        }
    }
}