@charset "utf-8";

@import '../mixin';
@import '../variable';

/* SASS UNTUK FOOTER */

#footer {
    background-color: #FFF;
    padding: 42px 15px 50px;
    @media screen and (max-width: $md) {
        padding: 30px 15px 67px;
    }
    @media screen and (max-width: $sm) {
        padding: 30px 15px 67px;
    }
    .inner {
        .logo {
            width: 226px;
            margin: 0 auto 16px;
            img {
                width: 100%;
            }
            @media screen and (min-width: $xxl) {
                width: 325px;
                margin: 0 auto 32px;
            }
        }
        address {
            text-align: center;
            font-size: 14px;
            margin-bottom: 36px;
            @media screen and (min-width: $xxl) {
                font-size: 18px;
            }
            @media screen and (max-width: $sm) {
                font-size: 12px;
            }
        }
        .cr {
            text-align: center;
            font-size: 13px;
            color: #818181;
            @media screen and (min-width: $xxl) {
                font-size: 15px;
            }
            @media screen and (max-width: $sm) {
                font-size: 10px;
            }
        }
    }
}

.float-btn {
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: $color-button;
    left: 0;
    bottom: 0;
    z-index: 5;
    color: #FFF;
    display: none;
    @media screen and (max-width: $md) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: $sm) {
        height: 45px;
    }
    div {
        width: 50%;
        &:last-child {
            border-left: 1px solid #FFFFFF;
        }
        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $animate;
            i {
                margin-right: 10px;
            }
            span {
                font-weight: bold;
                font-size: 16px;
                letter-spacing: 1px;
                @media screen and (max-width: $sm) {
                    font-size: 15px;
                }
            }
        }
    }
}